import { NgModule, ModuleWithProviders } from '@angular/core';
import { IGoogleAnalyticsCommand } from './interfaces/i-google-analytics-command';
import { NGX_GOOGLE_ANALYTICS_INITIALIZER_PROVIDER } from './initializers/google-analytics.initializer';
import { NGX_GOOGLE_ANALYTICS_SETTINGS_TOKEN } from './tokens/ngx-google-analytics-settings-token';
import { GaEventDirective } from './directives/ga-event.directive';
import { GaEventCategoryDirective } from './directives/ga-event-category.directive';
import { GaEventFormInputDirective } from './directives/ga-event-form-input.directive';
import { IGoogleAnalyticsSettings } from './interfaces/i-google-analytics-settings';
import {GoogleAnalyticsService} from "@core/utilities/analytics/services/google-analytics.service";

/**
 * Install Google Analytics Tracking code on your environment and configure tracking ID.
 *
 * This module should be a dependency on the highest level module of the application, i.e. AppModule in most use cases.
 */
@NgModule({
  imports: [
  ],
  declarations: [
    GaEventDirective,
    GaEventCategoryDirective,
    GaEventFormInputDirective
  ],
  exports: [
    GaEventDirective,
    GaEventCategoryDirective,
    GaEventFormInputDirective
  ]
})
export class NgxGoogleAnalyticsModule {
  static forRoot(): ModuleWithProviders<NgxGoogleAnalyticsModule> {
    return {
      ngModule: NgxGoogleAnalyticsModule,
      providers: [
        GoogleAnalyticsService,
      ]
    };
  }
}
