import { Component } from '@angular/core';
import {ModalController} from "@ionic/angular";
import {TranslateModule} from "@ngx-translate/core";

@Component({
  selector: 'app-cookies-dialog',
  templateUrl: './cookies-dialog.component.html',
  styleUrls: ['./cookies-dialog.component.scss'],
  standalone: true,
  imports: [
    TranslateModule
  ]
})
export class CookiesDialogComponent {
  constructor(
    private modalController: ModalController,
  ) {}

  public didClickAccept() {
    this.modalController.dismiss(null, 'all');
  }

  public didClickNecessary(): void {
    this.modalController.dismiss(null, 'some');
  }
}
