<div class="relative inline-block text-left w-full h-full rounded-md {{ showBorder ? 'border' : 'border-none'}} border-brand-gray-200 {{showShadow ? 'shadow-sm' : 'shadow-none'}} overflow-hidden">
	<button #button (click)="presentPopover($event)" id="click-trigger" type="button" class="[&>*]:pointer-events-none inline-flex w-full h-full items-center justify-between bg-white gap-2 py-2 px-4 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none" aria-expanded="true" aria-haspopup="true">
		<ng-content select="[content]"></ng-content>
		<svg class="-mr-1 ml-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
			<path fill-rule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clip-rule="evenodd" />
		</svg>
	</button>

	<ion-popover #popover showBackdrop="false" [isOpen]="isOpen" (didDismiss)="isOpen = false">
		<ng-template>
			<ng-content (click)="isOpen = false"></ng-content>
		</ng-template>
	</ion-popover>
</div>
