import { ActionReducer} from '@ngrx/store';
import { EHydrateActions } from './hydration.actions';
import {initialAppState, IRootState} from '@app/core/store/root.state';
import produce from 'immer';

export function hydrationMetaReducer(reducer: ActionReducer<IRootState>): ActionReducer<IRootState> {
  return (state: IRootState | undefined = initialAppState, action: any): IRootState => {
    let nextState: IRootState;

    switch (action.type) {
      case EHydrateActions.HydrateSuccess:
        nextState = produce(action.state as IRootState, (draftState) => {});

        return nextState;

      default:
        return reducer(state, action);
    }
  };
}
