<div class="relative h-full w-full">
  <div class="absolute bottom-2 left-2 right-2 flex flex-col gap-6 overflow-hidden rounded-lg bg-white p-4">
    <img src="assets/image/cookies.png" class="absolute -right-2.5 -top-2.5 h-12 w-12" />
    <div class="flex flex-col gap-2">
      <h2>{{ "cookies.title" | translate }}</h2>
      <p class="text-brand-gray-400">{{ "cookies.description" | translate }}</p>
      <a href="https://www.bodil.io/privatlivspolitk" class="text-brand-gray-400 underline">{{ "cookies.policy" | translate }}</a>
    </div>

    <div class="flex flex-col gap-4 md:flex-row">
      <button
        (click)="didClickAccept()"
        class="primary-button !rounded-full !bg-custom-secondary !py-5 !font-bold !text-custom-secondaryText">
        {{ "cookies.button.all" | translate }}
      </button>

      <button (click)="didClickNecessary()" class="primary-button !rounded-full !bg-brand-gray-200 !py-4 !font-bold !text-brand-gray-600">
        {{ "cookies.button.necessary" | translate }}
      </button>
    </div>
  </div>
</div>
