import { ElementRef, Injectable, Renderer2 } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { IRootState } from '@core/store/root.state';
import {
  AddToCart,
  AddToWaitlist,
  AddToWaitlistSuccess,
  CalculateCartPrice,
  CalculateCartPriceSuccess,
  CancelBooking,
  CancelBookingSuccess,
  ClearCart,
  ClearSelectedTimeSlot,
  CreateBooking,
  CreateBookingSuccess,
  CreateCustomer,
  CreateCustomerSuccess,
  CreateJWT,
  CreateJWTSuccess,
  EPublicActions,
  FetchAccountInformation,
  FetchAccountInformationSuccess,
  FetchAvailableBookingHours,
  FetchAvailableBookingHoursSuccess,
  FetchBookingHours,
  FetchBookingHoursSuccess,
  FetchBookingSettings,
  FetchBookingSettingsSuccess,
  FetchCategories,
  FetchCategoriesSuccess,
  FetchCustomerById,
  FetchCustomerByIdSuccess,
  FetchThemeSettings,
  FetchThemeSettingsSuccess,
  RemoveFromCart,
  RequestSMSChallenge,
  RequestSMSChallengeSuccess,
  UpdateAccountId,
  VerifySMSChallenge,
  VerifySMSChallengeSuccess,
} from '@core/store/public/public.actions';
import { catchError, from, mergeMap, of, withLatestFrom } from 'rxjs';
import { PublicService } from '@core/store/public/public.service';
import { LoadError } from '@core/store/error/error.actions';
import {
  selectPublicAccountId,
  selectPublicCart,
  selectPublicCartPrice,
  selectPublicCustomerToken,
  selectPublicSelectedTimeSlot,
  selectPublicSMSChallenge,
  selectWaitlistInfo,
} from '@core/store/public/public.selectors';
import { Router } from '@angular/router';
import { ICompletedBookingModel } from '@models/completed-booking.model';
import { PixelService } from '@core/utilities/pixel/pixel.service';
import { GoogleAnalyticsService } from '@core/utilities/analytics/services/google-analytics.service';
import WebFont from 'webfontloader';

function parseColor(color: any) {
  if (!color || typeof color !== 'string') {
    throw new Error('Invalid color format');
  }
  const components = color.split(' ').map(c => {
    const num = parseInt(c, 10);
    if (isNaN(num) || num < 0 || num > 255) {
      throw new Error('Invalid color component');
    }
    return num;
  });
  if (components.length !== 3) {
    throw new Error('Color must have three components');
  }
  return components;
}

function getLuminance(color: any) {
  const [r, g, b] = parseColor(color);
  return 0.2126 * r + 0.7152 * g + 0.0722 * b;
}

function getContrast(f: any, b: any) {
  const L1 = getLuminance(f);
  const L2 = getLuminance(b);
  return (Math.max(L1, L2) + 0.05) / (Math.min(L1, L2) + 0.05);
}

function getTextColor(rgbString: string): string {
  const rgbValues = rgbString.split(' ').map(value => parseInt(value, 10));

  if (rgbValues.length !== 3 || rgbValues.some(value => isNaN(value) || value < 0 || value > 255)) {
    throw new Error('Invalid RGB color');
  }
  const luminance = 0.2126 * rgbValues[0] + 0.7152 * rgbValues[1] + 0.0722 * rgbValues[2];

  return luminance > 140 ? '0 0 0' : '255 255 255';
}

@Injectable()
export class PublicEffects {
  constructor(
    private actions$: Actions,
    private store: Store<IRootState>,
    private publicService: PublicService,
    private router: Router,
    private pixel: PixelService,
    private google: GoogleAnalyticsService
  ) {}

  public onFetchAccountInformation = createEffect(() =>
    this.actions$.pipe(
      ofType<FetchAccountInformation>(EPublicActions.FetchAccountInformation),
      withLatestFrom(this.store.select(selectPublicAccountId)),
      mergeMap(([action, account_id]) =>
        from(this.publicService.getAccountInformation(action.subdomain)).pipe(
          mergeMap(info => {
            const actions: Action[] = [new FetchAccountInformationSuccess(info), new UpdateAccountId(info.id, action.subdomain)];

            if (info.id !== account_id) {
              actions.push(new ClearCart());
              actions.push(new ClearSelectedTimeSlot());
            }

            return actions;
          }),
          catchError(error => {
            this.router.navigate(['/not-found']);

            return [new LoadError(error, action)];
          })
        )
      )
    )
  );

  public onFetchThemeSettings = createEffect(() =>
    this.actions$.pipe(
      ofType<FetchThemeSettings>(EPublicActions.FetchThemeSettings),
      withLatestFrom(this.store.select(selectPublicAccountId)),
      mergeMap(([action, account_id]) =>
        from(this.publicService.getThemeSettings(account_id)).pipe(
          mergeMap(settings => {
            const primaryColor = settings.primary_color.replaceAll(',', ' ');
            const secondaryColor = settings.secondary_color.replaceAll(',', ' ');

            document.documentElement.style.setProperty('--color-custom-primary', primaryColor);
            document.documentElement.style.setProperty('--color-custom-secondary', secondaryColor);
            document.documentElement.style.setProperty('--color-custom-primaryText', getTextColor(primaryColor));
            document.documentElement.style.setProperty('--color-custom-secondaryText', getTextColor(secondaryColor));

            WebFont.load({
              google: {
                families: [settings.font_family],
              },
            });

            document.documentElement.style.setProperty('--color-custom-font', settings.font_family);

            return [new FetchThemeSettingsSuccess(settings)];
          }),
          catchError(error => of(new LoadError(error, action)))
        )
      )
    )
  );

  public onFetchBookingSettings = createEffect(() =>
    this.actions$.pipe(
      ofType<FetchBookingSettings>(EPublicActions.FetchBookingSettings),
      withLatestFrom(this.store.select(selectPublicAccountId)),
      mergeMap(([action, account_id]) =>
        from(this.publicService.getBookingSettings(account_id)).pipe(
          mergeMap(settings => [new FetchBookingSettingsSuccess(settings)]),
          catchError(error => of(new LoadError(error, action)))
        )
      )
    )
  );

  public onUpdateAccountId = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateAccountId>(EPublicActions.UpdateAccountId),
      withLatestFrom(this.store.select(selectPublicAccountId)),
      mergeMap(([action, id]) => [new FetchBookingHours(), new FetchThemeSettings(), new FetchBookingSettings()])
    )
  );

  public onFetchOpeningHours = createEffect(() =>
    this.actions$.pipe(
      ofType<FetchBookingHours>(EPublicActions.FetchBookingHours),
      withLatestFrom(this.store.select(selectPublicAccountId)),
      mergeMap(([action, account_id]) =>
        from(this.publicService.getOpeningHours(account_id)).pipe(
          mergeMap(openingHours => [new FetchBookingHoursSuccess(openingHours)]),
          catchError(error => of(new LoadError(error, action)))
        )
      )
    )
  );

  public onFetchCategories = createEffect(() =>
    this.actions$.pipe(
      ofType<FetchCategories>(EPublicActions.FetchCategories),
      withLatestFrom(this.store.select(selectPublicAccountId)),
      mergeMap(([action, account_id]) =>
        from(this.publicService.getCategories(account_id)).pipe(
          mergeMap(categories => [new FetchCategoriesSuccess(categories)]),
          catchError(error => of(new LoadError(error, action)))
        )
      )
    )
  );

  public onFetchAvailableBookingHours = createEffect(() =>
    this.actions$.pipe(
      ofType<FetchAvailableBookingHours>(EPublicActions.FetchAvailableBookingHours),
      withLatestFrom(this.store.select(selectPublicCart), this.store.select(selectPublicAccountId)),
      mergeMap(([action, cart, account_id]) =>
        from(this.publicService.getAvailableBookingHours(action.from_date, action.to_date, cart, account_id)).pipe(
          mergeMap(bookingHours => [new FetchAvailableBookingHoursSuccess(bookingHours)]),
          catchError(error => of(new LoadError(error, action)))
        )
      )
    )
  );

  public onRequestSMSChallenge = createEffect(() =>
    this.actions$.pipe(
      ofType<RequestSMSChallenge>(EPublicActions.RequestSMSChallenge),
      withLatestFrom(this.store.select(selectPublicAccountId)),
      mergeMap(([action, account_id]) =>
        from(this.publicService.requestSMSChallenge(action.phone, account_id)).pipe(
          mergeMap(challenge => [new RequestSMSChallengeSuccess(challenge)]),
          catchError(error => of(new LoadError(error, action)))
        )
      )
    )
  );

  public onVerifySMSChallenge = createEffect(() =>
    this.actions$.pipe(
      ofType<VerifySMSChallenge>(EPublicActions.VerifySMSChallenge),
      withLatestFrom(this.store.select(selectPublicSMSChallenge)),
      mergeMap(([action, smsChallenge]) =>
        from(this.publicService.verifySMSChallenge(action.code, smsChallenge!)).pipe(
          mergeMap(challenge => [new VerifySMSChallengeSuccess(challenge)]),
          catchError(error => of(new LoadError(error, action)))
        )
      )
    )
  );

  public onGetCustomerId = createEffect(() =>
    this.actions$.pipe(
      ofType<FetchCustomerById>(EPublicActions.FetchCustomerById),
      withLatestFrom(this.store.select(selectPublicCustomerToken)),
      mergeMap(([action, customer_token]) =>
        from(this.publicService.getCustomerById(customer_token!.token)).pipe(
          mergeMap(customer => {
            this.router.navigate(['/confirm'], { queryParamsHandling: 'merge' });

            return [new FetchCustomerByIdSuccess(customer)];
          }),
          catchError(error => of(new LoadError(error, action)))
        )
      )
    )
  );

  public onCreateJWT = createEffect(() =>
    this.actions$.pipe(
      ofType<CreateJWT>(EPublicActions.CreateJWT),
      withLatestFrom(this.store.select(selectPublicSMSChallenge)),
      mergeMap(([action, smsChallenge]) =>
        from(this.publicService.createJWT(smsChallenge!)).pipe(
          mergeMap(token => [new CreateJWTSuccess(token), new FetchCustomerById()]),
          catchError(error => of(new LoadError(error, action)))
        )
      )
    )
  );

  public onCreateCustomer = createEffect(() =>
    this.actions$.pipe(
      ofType<CreateCustomer>(EPublicActions.CreateCustomer),
      withLatestFrom(this.store.select(selectPublicSMSChallenge)),
      mergeMap(([action, smsChallenge]) =>
        from(this.publicService.createCustomer(action.customer, smsChallenge!)).pipe(
          mergeMap(customer => {
            this.pixel.track('CompleteRegistration');
            this.pixel.track('Lead');
            this.google.event('sign_up');

            return [new CreateCustomerSuccess(customer), new CreateJWT()];
          }),
          catchError(error => of(new LoadError(error, action)))
        )
      )
    )
  );

  public onCalculateCartPrice = createEffect(() =>
    this.actions$.pipe(
      ofType<CalculateCartPrice>(EPublicActions.CalculateCartPrice),
      withLatestFrom(this.store.select(selectPublicCart), this.store.select(selectPublicAccountId)),
      mergeMap(([action, cart, account_id]) =>
        from(this.publicService.postCalculateCartPrice(cart, account_id!)).pipe(
          mergeMap(price => [new CalculateCartPriceSuccess(price)]),
          catchError(error => of(new LoadError(error, action)))
        )
      )
    )
  );

  public onAddToCart = createEffect(() =>
    this.actions$.pipe(
      ofType<AddToCart>(EPublicActions.AddToCart),
      mergeMap(action => {
        this.pixel.track('AddToCart', { content_name: action.item.name, content_type: 'product' });
        this.google.event('add_to_cart', undefined, action.item.name);

        return [new CalculateCartPrice()];
      })
    )
  );

  public onRemoveFromCart = createEffect(() =>
    this.actions$.pipe(
      ofType<RemoveFromCart>(EPublicActions.RemoveFromCart),
      withLatestFrom(this.store.select(selectPublicCart)),
      mergeMap(([action, cart]) => {
        if (cart.length === 0) {
          return [new CalculateCartPriceSuccess({ total_amount: 0, total_vat_amount: 0 })];
        }

        return [new CalculateCartPrice()];
      })
    )
  );

  public onCreateBooking = createEffect(() =>
    this.actions$.pipe(
      ofType<CreateBooking>(EPublicActions.CreateBooking),
      withLatestFrom(
        this.store.select(selectPublicCart),
        this.store.select(selectPublicSelectedTimeSlot),
        this.store.select(selectPublicCustomerToken),
        this.store.select(selectPublicCartPrice)
      ),
      mergeMap(([action, cart, timeSlot, token, price]) =>
        from(this.publicService.postCreateBooking(timeSlot!, cart, token!.token, action.note)).pipe(
          mergeMap(booking => {
            this.router.navigate(['/success'], { queryParamsHandling: 'merge' });

            const completedBooking: ICompletedBookingModel = {
              price: price!,
              timeSlot: timeSlot!,
            };

            return [new CreateBookingSuccess(completedBooking)];
          }),
          catchError(error => of(new LoadError(error, action)))
        )
      )
    )
  );

  public onCreateBookingSuccess = createEffect(() =>
    this.actions$.pipe(
      ofType<CreateBookingSuccess>(EPublicActions.CreateBookingSuccess),
      mergeMap(action => {
        this.pixel.track('Schedule');
        this.google.event('generate_lead');
        this.google.event('booking_confirmed');

        return [new ClearCart(), new ClearSelectedTimeSlot()];
      })
    )
  );

  public onCancelBooking = createEffect(() =>
    this.actions$.pipe(
      ofType<CancelBooking>(EPublicActions.CancelBooking),
      withLatestFrom(this.store.select(selectPublicAccountId)),
      mergeMap(([action, account_id]) =>
        from(this.publicService.cancelBooking(account_id, action.booking_id)).pipe(
          mergeMap(() => [new CancelBookingSuccess()]),
          catchError(error => of(new LoadError(error, action)))
        )
      )
    )
  );

  public onAddToWaitlist = createEffect(() =>
    this.actions$.pipe(
      ofType<AddToWaitlist>(EPublicActions.AddToWaitlist),
      withLatestFrom(
        this.store.select(selectPublicCart),
        this.store.select(selectWaitlistInfo),
        this.store.select(selectPublicCustomerToken)
      ),
      mergeMap(([action, cart, waitlistInfo, token]) =>
        from(this.publicService.addToWaitlist(waitlistInfo, cart, token!.token)).pipe(
          mergeMap(() => {
            this.router.navigate(['/success'], { queryParamsHandling: 'merge' });

            return [new AddToWaitlistSuccess()];
          }),
          catchError(error => of(new LoadError(error, action)))
        )
      )
    )
  );
}
