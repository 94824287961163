import { IPublicState } from '@core/store/public/public.state';
import { createSelector } from '@ngrx/store';
import { IRootState } from '@core/store/root.state';

const _selectPublic = (state: IRootState) => state.public;

export const selectPublicIsLoading = createSelector(_selectPublic, (state: IPublicState) => state.isLoading);
export const selectPublicAccountId = createSelector(_selectPublic, (state: IPublicState) => state.account_id);
export const selectSettings = createSelector(_selectPublic, (state: IPublicState) => state.settings);
export const selectPublicAccountInformation = createSelector(_selectPublic, (state: IPublicState) => state.accountInformation);
export const selectPublicOpeningHours = createSelector(_selectPublic, (state: IPublicState) => state.openingHours);
export const selectPublicCategories = createSelector(_selectPublic, (state: IPublicState) => state.categories?.data);
export const selectPublicCart = createSelector(_selectPublic, (state: IPublicState) => state.cart);
export const selectPublicIsCartPriceLoading = createSelector(_selectPublic, (state: IPublicState) => state.isCartPriceLoading);
export const selectPublicCartPrice = createSelector(_selectPublic, (state: IPublicState) => state.cartPrice);
export const selectPublicAvailableBookingHours = createSelector(_selectPublic, (state: IPublicState) => state.availableBookingHours);
export const selectPublicSelectedTimeSlot = createSelector(_selectPublic, (state: IPublicState) => state.selectedTimeSlot);
export const selectPublicSMSChallenge = createSelector(_selectPublic, (state: IPublicState) => state.smsChallenge);
export const selectPublicCustomer = createSelector(_selectPublic, (state: IPublicState) => state.customer);
export const selectPublicCustomerToken = createSelector(_selectPublic, (state: IPublicState) => state.customer_t);
export const selectPublicCompletedBooking = createSelector(_selectPublic, (state: IPublicState) => state.completedBooking);
export const selectWaitlistInfo = createSelector(_selectPublic, (state: IPublicState) => state.waitlistInfo);
export const selectThemeSettings = createSelector(_selectPublic, (state: IPublicState) => state.themeSettings);
