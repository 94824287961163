import { Action } from '@ngrx/store';
import { PaginationHolder } from '@models/pagination.model';
import { ICategoryModel } from '@models/category.model';
import { IProductModel } from '@models/product.model';
import { ISelectedTimeslotModel } from '@app/public/pages/select-time/pipes/split-timeslots.model';
import { ISMSChallengeRequestModel } from '@models/sms-challenge-request.model';
import { IAccountInformationModel } from '@models/account-information.model';
import { ICustomerModel } from '@models/customer.model';
import { ITokenModel } from '@models/token.model';
import { ICartPriceModel } from '@models/cart-price.model';
import { ICompletedBookingModel } from '@models/completed-booking.model';
import { IOpeningHoursModel } from '@models/opening-hours.model';
import { IBookingSettingsModel } from '@models/booking-settings.model';
import { WaitlistInfo } from '@app/public/pages/waitlist/waitlist.component';
import { IBookingThemeSettings } from '@models/booking-theme-settings.model';

export enum EPublicActions {
  UpdateAccountId = '[PUBLIC] Update Account ID',

  FetchAccountInformation = '[PUBLIC] Fetch Account Information',
  FetchAccountInformationSuccess = '[PUBLIC] Fetch Account Information Success',

  FetchThemeSettings = '[PUBLIC] Fetch Theme Settings',
  FetchThemeSettingsSuccess = '[PUBLIC] Fetch Theme Settings Success',

  FetchBookingSettings = '[PUBLIC] Fetch Booking Settings',
  FetchBookingSettingsSuccess = '[PUBLIC] Fetch Booking Settings Success',

  FetchBookingHours = '[PUBLIC] Fetch Booking Hours',
  FetchBookingHoursSuccess = '[PUBLIC] Fetch Booking Hours Success',

  FetchCategories = '[PUBLIC] Fetch Categories',
  FetchCategoriesSuccess = '[PUBLIC] Fetch Categories Success',

  AddToCart = '[PUBLIC] Add To Cart',
  RemoveFromCart = '[PUBLIC] Remove From Cart',
  ClearCart = '[PUBLIC] Clear Cart',

  CalculateCartPrice = '[PUBLIC] Calculate Cart Price',
  CalculateCartPriceSuccess = '[PUBLIC] Calculate Cart Price Success',

  FetchAvailableBookingHours = '[PUBLIC] Fetch Available Booking Hours',
  FetchAvailableBookingHoursSuccess = '[PUBLIC] Fetch Available Booking Hours Success',

  SelectTimeSlot = '[PUBLIC] Select Time Slot',
  ClearSelectedTimeSlot = '[PUBLIC] Clear Selected Time Slot',

  RequestSMSChallenge = '[PUBLIC] Request SMS Challenge',
  RequestSMSChallengeSuccess = '[PUBLIC] Request SMS Challenge Success',

  VerifySMSChallenge = '[PUBLIC] Verify SMS Challenge',
  VerifySMSChallengeSuccess = '[PUBLIC] Verify SMS Challenge Success',

  FetchCustomerById = '[PUBLIC] Fetch Customer Id',
  FetchCustomerByIdSuccess = '[PUBLIC] Fetch Customer Id Success',

  CreateJWT = '[PUBLIC] Create JWT',
  CreateJWTSuccess = '[PUBLIC] Create JWT Success',

  CreateCustomer = '[PUBLIC] Create Customer',
  CreateCustomerSuccess = '[PUBLIC] Create Customer Success',

  CreateBooking = '[PUBLIC] Create Booking',
  CreateBookingSuccess = '[PUBLIC] Create Booking Success',

  CancelBooking = '[PUBLIC] Cancel Booking',
  CancelBookingSuccess = '[PUBLIC] Cancel Booking Success',

  LogOut = '[PUBLIC] Log Out',

  UpdateWaitlistInfo = '[PUBLIC] Update Waitlist Info',
  AddToWaitlist = '[PUBLIC] Add To Waitlist',
  AddToWaitlistSuccess = '[PUBLIC] Add To Waitlist Success',
}

// Update Account ID

export class UpdateAccountId implements Action {
  public readonly type = EPublicActions.UpdateAccountId;

  constructor(
    public account_id?: string,
    public subdomain?: string
  ) {}
}

// Fetch Account Information

export class FetchAccountInformation implements Action {
  public readonly type = EPublicActions.FetchAccountInformation;

  constructor(public subdomain: string) {}
}

export class FetchAccountInformationSuccess implements Action {
  public readonly type = EPublicActions.FetchAccountInformationSuccess;

  constructor(public accountInformation: IAccountInformationModel) {}
}

// Fetch Theme Settings

export class FetchThemeSettings implements Action {
  public readonly type = EPublicActions.FetchThemeSettings;

  constructor() {}
}

export class FetchThemeSettingsSuccess implements Action {
  public readonly type = EPublicActions.FetchThemeSettingsSuccess;

  constructor(public settings: IBookingThemeSettings) {}
}

// Fetch Booking Settings

export class FetchBookingSettings implements Action {
  public readonly type = EPublicActions.FetchBookingSettings;

  constructor() {}
}

export class FetchBookingSettingsSuccess implements Action {
  public readonly type = EPublicActions.FetchBookingSettingsSuccess;

  constructor(public settings: IBookingSettingsModel) {}
}

// Fetch Booking Hours

export class FetchBookingHours implements Action {
  public readonly type = EPublicActions.FetchBookingHours;

  constructor() {}
}

export class FetchBookingHoursSuccess implements Action {
  public readonly type = EPublicActions.FetchBookingHoursSuccess;

  constructor(public openingHours: PaginationHolder<IOpeningHoursModel>) {}
}

// Fetch Categories

export class FetchCategories implements Action {
  public readonly type = EPublicActions.FetchCategories;

  constructor() {}
}

export class FetchCategoriesSuccess implements Action {
  public readonly type = EPublicActions.FetchCategoriesSuccess;

  constructor(public categories: PaginationHolder<ICategoryModel>) {}
}

// Cart

export class AddToCart implements Action {
  public readonly type = EPublicActions.AddToCart;

  constructor(
    public item: IProductModel,
    public category: ICategoryModel,
    public quantity: number
  ) {}
}

export class RemoveFromCart implements Action {
  public readonly type = EPublicActions.RemoveFromCart;

  constructor(public item: IProductModel) {}
}

export class ClearCart implements Action {
  public readonly type = EPublicActions.ClearCart;

  constructor() {}
}

// Calculate Cart Price

export class CalculateCartPrice implements Action {
  public readonly type = EPublicActions.CalculateCartPrice;

  constructor() {}
}

export class CalculateCartPriceSuccess implements Action {
  public readonly type = EPublicActions.CalculateCartPriceSuccess;

  constructor(public price: ICartPriceModel) {}
}

// Fetch Available Booking Hours

export class FetchAvailableBookingHours implements Action {
  public readonly type = EPublicActions.FetchAvailableBookingHours;

  constructor(
    public from_date: string,
    public to_date: string
  ) {}
}

export class FetchAvailableBookingHoursSuccess implements Action {
  public readonly type = EPublicActions.FetchAvailableBookingHoursSuccess;

  constructor(public availableBookingHours: any) {}
}

// Select Time Slot

export class SelectTimeSlot implements Action {
  public readonly type = EPublicActions.SelectTimeSlot;

  constructor(public selectedTimeSlot: ISelectedTimeslotModel) {}
}

export class ClearSelectedTimeSlot implements Action {
  public readonly type = EPublicActions.ClearSelectedTimeSlot;

  constructor() {}
}

// Request SMS Challenge

export class RequestSMSChallenge implements Action {
  public readonly type = EPublicActions.RequestSMSChallenge;

  constructor(public phone: string) {}
}

export class RequestSMSChallengeSuccess implements Action {
  public readonly type = EPublicActions.RequestSMSChallengeSuccess;

  constructor(public challenge: ISMSChallengeRequestModel) {}
}

// Verify SMS Challenge

export class VerifySMSChallenge implements Action {
  public readonly type = EPublicActions.VerifySMSChallenge;

  constructor(public code: number) {}
}

export class VerifySMSChallengeSuccess implements Action {
  public readonly type = EPublicActions.VerifySMSChallengeSuccess;

  constructor(public challenge: ISMSChallengeRequestModel) {}
}

// Fetch Customer ID

export class FetchCustomerById implements Action {
  public readonly type = EPublicActions.FetchCustomerById;

  constructor() {}
}

export class FetchCustomerByIdSuccess implements Action {
  public readonly type = EPublicActions.FetchCustomerByIdSuccess;

  constructor(public customer: ICustomerModel) {}
}

// CCreateJWT

export class CreateJWT implements Action {
  public readonly type = EPublicActions.CreateJWT;

  constructor() {}
}

export class CreateJWTSuccess implements Action {
  public readonly type = EPublicActions.CreateJWTSuccess;

  constructor(public token: ITokenModel) {}
}

// Create Customer

export class CreateCustomer implements Action {
  public readonly type = EPublicActions.CreateCustomer;

  constructor(public customer: ICustomerModel) {}
}

export class CreateCustomerSuccess implements Action {
  public readonly type = EPublicActions.CreateCustomerSuccess;

  constructor(public customer: ICustomerModel) {}
}

// Create Booking

export class CreateBooking implements Action {
  public readonly type = EPublicActions.CreateBooking;

  constructor(public note?: string) {}
}

export class CreateBookingSuccess implements Action {
  public readonly type = EPublicActions.CreateBookingSuccess;

  constructor(public booking: ICompletedBookingModel) {}
}

// Cancel Booking

export class CancelBooking implements Action {
  public readonly type = EPublicActions.CancelBooking;

  constructor(public booking_id: string) {}
}

export class CancelBookingSuccess implements Action {
  public readonly type = EPublicActions.CancelBookingSuccess;

  constructor() {}
}

// Log Out

export class LogOut implements Action {
  public readonly type = EPublicActions.LogOut;

  constructor() {}
}

// Update Waitlist Info

export class UpdateWaitlistInfo implements Action {
  public readonly type = EPublicActions.UpdateWaitlistInfo;

  constructor(public info: WaitlistInfo) {}
}

// Update Waitlist Info

export class AddToWaitlist implements Action {
  public readonly type = EPublicActions.AddToWaitlist;

  constructor() {}
}

export class AddToWaitlistSuccess implements Action {
  public readonly type = EPublicActions.AddToWaitlistSuccess;

  constructor() {}
}

export type PublicActions =
  | UpdateAccountId
  | FetchAccountInformation
  | FetchAccountInformationSuccess
  | FetchThemeSettings
  | FetchThemeSettingsSuccess
  | FetchBookingSettings
  | FetchBookingSettingsSuccess
  | FetchBookingHours
  | FetchBookingHoursSuccess
  | FetchCategories
  | FetchCategoriesSuccess
  | AddToCart
  | RemoveFromCart
  | ClearCart
  | CalculateCartPrice
  | CalculateCartPriceSuccess
  | FetchAvailableBookingHours
  | FetchAvailableBookingHoursSuccess
  | SelectTimeSlot
  | ClearSelectedTimeSlot
  | RequestSMSChallenge
  | RequestSMSChallengeSuccess
  | VerifySMSChallenge
  | VerifySMSChallengeSuccess
  | FetchCustomerById
  | FetchCustomerByIdSuccess
  | CreateJWT
  | CreateJWTSuccess
  | CreateCustomer
  | CreateCustomerSuccess
  | CreateBooking
  | CreateBookingSuccess
  | CancelBooking
  | CancelBookingSuccess
  | LogOut
  | UpdateWaitlistInfo
  | AddToWaitlist
  | AddToWaitlistSuccess;
