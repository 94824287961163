import {EffectsModule} from "@ngrx/effects";
import {CommonModule} from "@angular/common";
import {StoreModule} from "@ngrx/store";
import {PublicEffects} from "@core/store/public/public.effects";
import {NgModule} from "@angular/core";
import {publicReducers} from "@core/store/public/public.reducers";

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature('public', publicReducers),
        EffectsModule.forFeature([PublicEffects]),
    ],
    providers: [PublicEffects],
})
export class PublicStoreModule {
}
