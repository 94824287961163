import {ENavigationActions, NavigationActions} from "@core/store/navigation/navigation.actions";
import {INavigationState, initialNavigationState} from "@core/store/navigation/navigation.state";
import {ErrorActions} from "@core/store/error/error.actions";

export const navigationReducers = (
	state = initialNavigationState,
	action: NavigationActions | ErrorActions,
): INavigationState => {
	switch (action.type) {
		default:
			return state;
	}
};
