import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { metaReducers, rootReducers } from "@app/core/store/root.reducers";
import { getInitialState } from "@app/core/store/root.state";
import { rootEffects } from "@app/core/store/root.effects";
import { ErrorStoreModule } from "@app/core/store/error/error.module";
import {PublicStoreModule} from "@core/store/public/public-store.module";
import {NavigationStoreModule} from "@core/store/navigation/navigation-store.module";

@NgModule({
	imports: [
    StoreModule.forRoot(rootReducers,{initialState: getInitialState(), metaReducers}),
    EffectsModule.forRoot(rootEffects),
		PublicStoreModule,
    NavigationStoreModule,
		ErrorStoreModule,
	],
})
export class RootStoreModule {}
