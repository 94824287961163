import { RouterReducerState } from "@ngrx/router-store";
import {initialPublicState, IPublicState} from "@core/store/public/public.state";
import {INavigationState, initialNavigationState} from "@core/store/navigation/navigation.state";

export interface IRootState {
	router?: RouterReducerState;
  navigation: INavigationState;
  public: IPublicState;
}

export const initialAppState: IRootState = {
	public: initialPublicState,
  navigation: initialNavigationState,
};

export function getInitialState(): IRootState {
	return initialAppState;
}
