import { ActionReducerMap, MetaReducer } from "@ngrx/store";
import { IRootState } from "@app/core/store/root.state";
import { routerReducer } from "@ngrx/router-store";
import { hydrationMetaReducer } from "@app/core/store/hydration/hydration.reducer";
import {publicReducers} from "@core/store/public/public.reducers";
import {navigationReducers} from "@core/store/navigation/navigation.reducers";

export const metaReducers: MetaReducer[] = [hydrationMetaReducer];

export const rootReducers: ActionReducerMap<IRootState, any> = {
	router: routerReducer,
  navigation: navigationReducers,
  public: publicReducers,
};
