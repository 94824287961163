import { Action } from '@ngrx/store';

export enum ENavigationActions {
	NavigateTo = '[Navigation] Navigate To Some Path',
}

export class NavigateTo implements Action {
	public readonly type = ENavigationActions.NavigateTo;

	constructor(public commands: [any], public queryParams?: object) {}
}

export type NavigationActions = NavigateTo;
