import {Store} from "@ngrx/store";
import {IRootState} from "@app/core/store/root.state";
import {Hydrate} from "@core/store/hydration/hydration.actions";
import {Injector} from "@angular/core";
import {LOCATION_INITIALIZED} from "@angular/common";
import {TranslateService} from "@ngx-translate/core";

export function appInitializer(store: Store<IRootState>, injector: Injector, translate: TranslateService): () => Promise<any> {
	return (): Promise<any> => new Promise(async (resolve) => {
    store.dispatch(new Hydrate());

    await injector.get(LOCATION_INITIALIZED, Promise.resolve(null));

    try {
      await translate.use('da').toPromise();
    } catch (err) {
      console.error(err);
    }

    return resolve(true);
  });
}
