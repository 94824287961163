import produce from "immer";
import {EErrorActions, ErrorActions} from "@app/core/store/error/error.actions";
import {IPublicState, initialPublicState} from "@app/core/store/public/public.state";
import {PublicActions, EPublicActions} from "@app/core/store/public/public.actions";

export const publicReducers = (state: IPublicState = initialPublicState, action: PublicActions | ErrorActions): IPublicState => {
  let nextState;

  switch (action.type) {
    // Update Account ID

    case EPublicActions.UpdateAccountId:
      nextState = produce(state, (draftState) => {
        draftState.account_id = action.account_id;
        draftState.subdomain = action.subdomain;
      });

      return nextState;

    // Fetch Theme Settings

    case EPublicActions.FetchThemeSettings:
      nextState = produce(state, (draftState) => {
        draftState.isLoading = true;
      });

      return nextState;

    case EPublicActions.FetchThemeSettingsSuccess:
      nextState = produce(state, (draftState) => {
        draftState.isLoading = false;
        draftState.themeSettings = action.settings;
      });

      return nextState;


    // Fetch Settings

    case EPublicActions.FetchBookingSettings:
      nextState = produce(state, (draftState) => {
        draftState.isLoading = true;
      });

      return nextState;

    case EPublicActions.FetchBookingSettingsSuccess:
      nextState = produce(state, (draftState) => {
        draftState.isLoading = false;
        draftState.settings = action.settings;
      });

      return nextState;


    // Fetch Booking Hours

    case EPublicActions.FetchBookingHours:
      nextState = produce(state, (draftState) => {
        draftState.isLoading = true;
      });

      return nextState;

    case EPublicActions.FetchBookingHoursSuccess:
      nextState = produce(state, (draftState) => {
        draftState.isLoading = false;
        draftState.openingHours = action.openingHours;
      });

      return nextState;

    // Fetch Public Categories

    case EPublicActions.FetchAccountInformation:
      nextState = produce(state, (draftState) => {
        draftState.isLoading = true;
      });

      return nextState;

    case EPublicActions.FetchAccountInformationSuccess:
      nextState = produce(state, (draftState) => {
        draftState.isLoading = false;
        draftState.accountInformation = action.accountInformation;
      });

      return nextState;

    // Fetch Public Categories

    case EPublicActions.FetchCategories:
      nextState = produce(state, (draftState) => {
        draftState.isLoading = true;
      });

      return nextState;

    case EPublicActions.FetchCategoriesSuccess:
      nextState = produce(state, (draftState) => {
        draftState.isLoading = false;
        draftState.categories = action.categories;
      });

      return nextState;

    // Cart

    case EPublicActions.AddToCart:
      nextState = produce(state, (draftState) => {
        draftState.cart.push({
          product: action.item,
          category_id: action.category.id,
          quantity: action.quantity,
        });
      });

      return nextState;

    case EPublicActions.RemoveFromCart:
      nextState = produce(state, (draftState) => {
        draftState.cart = draftState.cart.filter(cartItem => cartItem.product.id !== action.item.id);
      });

      return nextState;

    case EPublicActions.ClearCart:
      nextState = produce(state, (draftState) => {
        draftState.cart = [];
        draftState.cartPrice = undefined;
      });

      return nextState;

    // Calculate Cart Price

    case EPublicActions.CalculateCartPrice:
      nextState = produce(state, (draftState) => {
        draftState.isCartPriceLoading = true;
      });

      return nextState;

    case EPublicActions.CalculateCartPriceSuccess:
      nextState = produce(state, (draftState) => {
        draftState.isCartPriceLoading = false;
        draftState.cartPrice = action.price;
      });

      return nextState;

    // Fetch Available Booking Hours

    case EPublicActions.FetchAvailableBookingHours:
      nextState = produce(state, (draftState) => {
        draftState.isLoading = true;
      });

      return nextState;
    case EPublicActions.FetchAvailableBookingHoursSuccess:
      nextState = produce(state, (draftState) => {
        draftState.isLoading = false;
        draftState.availableBookingHours = action.availableBookingHours;
      });

      return nextState;

    // Select Time Slot

    case EPublicActions.SelectTimeSlot:
      nextState = produce(state, (draftState) => {
        draftState.selectedTimeSlot = action.selectedTimeSlot;
      });

      return nextState;

    case EPublicActions.ClearSelectedTimeSlot:
      nextState = produce(state, (draftState) => {
        draftState.selectedTimeSlot = undefined;
      });

      return nextState;

    // Request SMS Challenge

    case EPublicActions.RequestSMSChallenge:
      nextState = produce(state, (draftState) => {
        draftState.isLoading = true;
      });

      return nextState;
    case EPublicActions.RequestSMSChallengeSuccess:
      nextState = produce(state, (draftState) => {
        draftState.isLoading = false;
        draftState.smsChallenge = action.challenge;
      });

      return nextState;

    // Verify SMS Challenge

    case EPublicActions.VerifySMSChallenge:
      nextState = produce(state, (draftState) => {
        draftState.isLoading = true;
      });

      return nextState;
    case EPublicActions.VerifySMSChallengeSuccess:
      nextState = produce(state, (draftState) => {
        draftState.isLoading = false;
        draftState.smsChallenge = action.challenge;
      });

      return nextState;

    // Fetch Customer By ID

    case EPublicActions.FetchCustomerById:
      nextState = produce(state, (draftState) => {
        draftState.isLoading = true;
      });

      return nextState;
    case EPublicActions.FetchCustomerByIdSuccess:
      nextState = produce(state, (draftState) => {
        draftState.isLoading = false;
        draftState.customer = action.customer;
      });

      return nextState;

    // Create JWT

    case EPublicActions.CreateJWT:
      nextState = produce(state, (draftState) => {
        draftState.isLoading = true;
      });

      return nextState;
    case EPublicActions.CreateJWTSuccess:
      nextState = produce(state, (draftState) => {
        draftState.isLoading = false;
        draftState.customer_t = action.token;
      });

      return nextState;

    // Create Customer

    case EPublicActions.CreateCustomer:
      nextState = produce(state, (draftState) => {
        draftState.isLoading = true;
      });

      return nextState;
    case EPublicActions.CreateCustomerSuccess:
      nextState = produce(state, (draftState) => {
        draftState.isLoading = false;
        draftState.customer = action.customer;
      });

      return nextState;

    // Create Customer

    case EPublicActions.CreateBooking:
      nextState = produce(state, (draftState) => {
        draftState.isLoading = true;
      });

      return nextState;
    case EPublicActions.CreateBookingSuccess:
      nextState = produce(state, (draftState) => {
        draftState.isLoading = false;
        draftState.completedBooking = action.booking;
      });

      return nextState;

    // Create Customer

    case EPublicActions.CancelBooking:
      nextState = produce(state, (draftState) => {
        draftState.isLoading = true;
      });

      return nextState;
    case EPublicActions.CancelBookingSuccess:
      nextState = produce(state, (draftState) => {
        draftState.isLoading = false;
      });

      return nextState;

    // Log Out

    case EPublicActions.LogOut:
      nextState = produce(state, (draftState) => {
        draftState.smsChallenge = undefined;
        draftState.customer = undefined;
        draftState.customer_t = undefined;
        draftState.completedBooking = undefined;
      });

      return nextState;

    // Update Waitlist Info

    case EPublicActions.UpdateWaitlistInfo:
      nextState = produce(state, (draftState) => {
        draftState.waitlistInfo = action.info;
      });

      return nextState;

    // Add to waitlist

    case EPublicActions.AddToWaitlist:
      nextState = produce(state, (draftState) => {
        draftState.isLoading = true;
      });

      return nextState;

    case EPublicActions.AddToWaitlistSuccess:
      nextState = produce(state, (draftState) => {
        draftState.isLoading = false;
      });

      return nextState;

    // ERROR

    case EErrorActions.LoadError:
      nextState = produce(state, (draftState) => {
        draftState.isLoading = false;
        draftState.isCartPriceLoading = false;
      });

      return nextState;
    default:
      return state;
  }
};

