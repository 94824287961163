import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { IonicModule } from '@ionic/angular';
import { environment } from '@env/environment';
import { EEnvironment } from '@enums/environment.enum';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { LogContentModule } from '@app/shared/components/log-content/log-content.module';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SecureGuard } from '@core/guards/secure.guard';
import { CommonModule } from '@angular/common';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { RootStoreModule } from '@core/store/root-store.module';
import { LocalStorageService } from '@services/local-storage/local-storage.service';
import { Store } from '@ngrx/store';
import { appInitializer } from '@app/app-initializer';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { CustomTranslateLoader } from '@services/language/translate-loader';
import { DropdownComponent } from '@app/shared/components/dropdown/dropdown.component';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { LanguageService } from '@services/language/language.service';
import { SentryErrorHandler } from '@core/utilities/sentry/sentry.handler';
import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular-ivy';
import { PixelModule } from '@core/utilities/pixel/pixel.module';
import { NgxGoogleAnalyticsModule } from '@core/utilities/analytics/ngx-google-analytics.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    RootStoreModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: CustomTranslateLoader,
        deps: [HttpClient],
      },
    }),
    StoreRouterConnectingModule.forRoot({ stateKey: 'router' }),
    environment.env !== EEnvironment.production ? StoreDevtoolsModule.instrument() : [],
    LogContentModule,
    IonicModule.forRoot(),
    DropdownComponent,
    PixelModule.forRoot({ enabled: false, pixelId: '' }),
    NgxGoogleAnalyticsModule.forRoot(),
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      multi: true,
      deps: [Store, Injector, TranslateService],
    },
    SecureGuard,
    TranslateService,
    MomentDateAdapter,
    environment.env !== EEnvironment.preview ? { provide: ErrorHandler, useClass: SentryErrorHandler } : [],
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    { provide: MAT_DATE_LOCALE, useFactory: getLocale, deps: [LanguageService] },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

export function getLocale(interService: LanguageService): string {
  return interService.getCurrentLanguage().angularKey;
}
