import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LogContentComponent } from './containers/log-content/log-content.component';
import { LogContentBoxComponent } from './containers/log-content-box/log-content-box.component';

@NgModule({
	declarations: [LogContentComponent, LogContentBoxComponent],
	imports: [CommonModule],
	exports: [LogContentComponent, LogContentBoxComponent],
})
export class LogContentModule {}
