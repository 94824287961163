import {AfterViewInit, Component, ElementRef, Input, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {CommonModule} from "@angular/common";
import {IonicModule} from "@ionic/angular";

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  imports: [CommonModule, IonicModule],
  standalone: true,
})
export class DropdownComponent implements OnInit, AfterViewInit {
	@ViewChild('popover') public popover: any;
	@ViewChild('content') public innerContent: TemplateRef<any> | undefined;

	@ViewChild('button') public btn: ElementRef | undefined;

  @Input() public showShadow = true;
  @Input() public showBorder = true;

	public isOpen = false;

  constructor() { }

  public ngOnInit(): void {
  }

	public ngAfterViewInit(): void {
		this.popover.el.style.setProperty('--width', this.btn?.nativeElement.offsetWidth + 'px');
	}

	public presentPopover(e: Event): void {
		this.popover.el.style.setProperty('--width', this.btn?.nativeElement.offsetWidth + 'px');
		this.popover.event = e;
		this.isOpen = true;
	}

  public closePopover(): void {
    this.isOpen = false;
  }
}
