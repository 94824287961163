import { NgModule } from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {NotFoundComponent} from "@app/public/pages/not-found/not-found.component";
import {SecureGuard} from "@core/guards/secure.guard";
import {SubdomainResolver} from "@core/utilities/resolvers/subdomain.resolver";

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'loading',
  },
  {
    path: '',
    resolve: [
      SubdomainResolver
    ],
    canActivate: [
      SecureGuard,
    ],
    loadChildren: () =>
      import('@app/public/public.module').then((m) => m.PublicModule),
  },
  {
    path: 'not-found',
    canActivate: [
      SecureGuard,
    ],
    component: NotFoundComponent,
    children: [],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: false,
      scrollPositionRestoration: 'top',
      anchorScrolling: 'enabled',
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
