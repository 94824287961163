import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { PaginationHolder } from '@models/pagination.model';
import { ICategoryModel } from '@models/category.model';
import { environment } from '@env/environment';
import { IBookingHourModel } from '@models/booking-hour.model';
import { ISMSChallengeRequestModel } from '@models/sms-challenge-request.model';
import { IAccountInformationModel } from '@models/account-information.model';
import { ICustomerModel } from '@models/customer.model';
import { ITokenModel } from '@models/token.model';
import { ICartItemModel } from '@models/cart-item.model';
import { ICartPriceModel } from '@models/cart-price.model';
import { ISelectedTimeslotModel } from '@app/public/pages/select-time/pipes/split-timeslots.model';
import { EProductType } from '@enums/product-type.enum';
import { IOpeningHoursModel } from '@models/opening-hours.model';
import { IBookingSettingsModel } from '@models/booking-settings.model';
import { WaitlistInfo } from '@app/public/pages/waitlist/waitlist.component';
import { IProductModel } from '@models/product.model';
import { IBookingThemeSettings } from '@models/booking-theme-settings.model';

@Injectable({
  providedIn: 'root',
})
export class PublicService {
  constructor(private http: HttpClient) {}

  public getAccountInformation(subdomain: string | undefined): Observable<IAccountInformationModel> {
    if (subdomain === undefined) {
      return throwError(() => 'Subdomain is undefined');
    }

    return this.http.get<IAccountInformationModel>(`${environment.api_url}/public/accounts/subdomain/${subdomain}`);
  }

  public getThemeSettings(account_id?: string): Observable<IBookingThemeSettings> {
    if (account_id === undefined) {
      return throwError(() => 'Account ID is undefined');
    }

    return this.http.get<IBookingThemeSettings>(`${environment.api_url}/public/booking-theme-settings?account_id=${account_id}`);
  }

  public getBookingSettings(account_id?: string): Observable<IBookingSettingsModel> {
    if (account_id === undefined) {
      return throwError(() => 'Account ID is undefined');
    }

    return this.http.get<IBookingSettingsModel>(`${environment.api_url}/public/booking-settings?account_id=${account_id}`);
  }

  public getOpeningHours(account_id: string | undefined): Observable<PaginationHolder<IOpeningHoursModel>> {
    if (account_id === undefined) {
      return throwError(() => 'Account ID is undefined');
    }

    return this.http.get<PaginationHolder<IOpeningHoursModel>>(`${environment.api_url}/public/booking-hours`, {
      params: {
        account_id,
      },
    });
  }

  public getCategories(account_id: string | undefined): Observable<PaginationHolder<ICategoryModel>> {
    if (account_id === undefined) {
      return throwError(() => 'Account ID is undefined');
    }

    return this.http.get<PaginationHolder<ICategoryModel>>(`${environment.api_url}/public/categories`, {
      params: {
        account_id,
      },
    });
  }

  public getAvailableBookingHours(
    from_date: string,
    to_date: string,
    products: ICartItemModel[],
    account_id: string | undefined
  ): Observable<IBookingHourModel[]> {
    if (account_id === undefined) {
      return throwError(() => 'Account ID is undefined');
    }

    return this.http.post<IBookingHourModel[]>(`${environment.api_url}/public/bookings/timeslots`, {
      account_id,
      from_date,
      to_date,
      products: products.filter(cartItem => cartItem.product.type === EProductType.TREATMENT).map(prod => ({ id: prod.product.id })),
    });
  }

  public requestSMSChallenge(phone: string, account_id: string | undefined): Observable<ISMSChallengeRequestModel> {
    if (account_id === undefined) {
      return throwError(() => 'Account ID is undefined');
    }

    return this.http.post<ISMSChallengeRequestModel>(`${environment.api_url}/public/customers/sms-challenges`, {
      phone,
      account_id,
    });
  }

  public verifySMSChallenge(code: number, challenge: ISMSChallengeRequestModel): Observable<any> {
    return this.http.post<any>(`${environment.api_url}/public/customers/sms-challenges/${challenge.id}/verify`, {
      pin_code: code,
    });
  }

  public getCustomerById(token: string): Observable<ICustomerModel> {
    return this.http.get<ICustomerModel>(`${environment.api_url}/public/customers`, {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    });
  }

  public createJWT(challenge: ISMSChallengeRequestModel): Observable<ITokenModel> {
    return this.http.post<ITokenModel>(`${environment.api_url}/public/customers/sms-challenges/${challenge.id}/tokens`, {});
  }

  public createCustomer(customer: ICustomerModel, challenge: ISMSChallengeRequestModel): Observable<ICustomerModel> {
    return this.http.post<ICustomerModel>(`${environment.api_url}/public/customers/sms-challenges/${challenge.id}/customers`, customer);
  }

  public postCalculateCartPrice(cart: ICartItemModel[], account_id: string): Observable<ICartPriceModel> {
    const lines = cart.map(cartItem => ({
      product_id: cartItem.product.id,
      category_id: cartItem.category_id,
      quantity: cartItem.quantity,
    }));

    return this.http.post<ICartPriceModel>(`${environment.api_url}/public/basket-calculation`, {
      lines: lines,
      account_id,
    });
  }

  public postCreateBooking(
    selected_time: ISelectedTimeslotModel,
    cart: ICartItemModel[],
    token: string,
    note?: string
  ): Observable<ICartPriceModel> {
    const lines = cart.map(cartItem => ({
      product_id: cartItem.product.id,
      category_id: cartItem.category_id,
      quantity: cartItem.quantity,
    }));

    return this.http.post<ICartPriceModel>(
      `${environment.api_url}/public/bookings`,
      {
        order: { lines },
        from_date: selected_time.timeslot.from_date,
        to_date: selected_time.timeslot.to_date,
        user_id: selected_time.user.id,
        note,
      },
      {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      }
    );
  }

  public cancelBooking(account_id: string | undefined, booking_id: string): Observable<any> {
    if (account_id === undefined) {
      return throwError(() => 'Account ID is undefined');
    }

    return this.http.patch<any>(`${environment.api_url}/public/bookings/cancel`, {
      account_id,
      booking_id,
    });
  }

  public addToWaitlist(waitlistInfo: WaitlistInfo | undefined, cart: ICartItemModel[], token: string): Observable<any> {
    if (!waitlistInfo) {
      return throwError(() => 'Waitlist data is undefined');
    }

    const body = {
      as_soon_as_possible: waitlistInfo.asap,
      from_time: waitlistInfo.fromTime,
      to_time: waitlistInfo.toTime,
      desired_dates: waitlistInfo.dates.map(d => ({ date: d })),
      desired_products: cart.map(i => ({ product_id: i.product.id })),
    };

    return this.http.post<any>(`${environment.api_url}/public/customers/waiting-list`, body, {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    });
  }
}
