import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {EffectsModule} from '@ngrx/effects';
import {ErrorEffects} from '@app/core/store/error/error.effects';

@NgModule({
	imports: [
		CommonModule,
		EffectsModule.forFeature([ErrorEffects]),
	],
	providers: [ErrorEffects],
})
export class ErrorStoreModule {
}
