import { EventEmitter, Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ILogContent } from "@app/shared/components/log-content/interfaces/log-content.interface";

@Injectable({
	providedIn: 'root',
})
export class LogContentService {
	public logContentChange: Subject<ILogContent> = new EventEmitter();

	public logContent(logContent: ILogContent): void {
		this.logContentChange.next(logContent);
	}
}
