import {Component, OnInit} from '@angular/core';
import {Language} from "@services/language/language-base.service";
import {LanguageService} from "@services/language/language.service";
import {Store} from "@ngrx/store";
import {IRootState} from "@core/store/root.state";
import {Router} from "@angular/router";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {PixelService} from "@core/utilities/pixel/pixel.service";
import {ModalController} from "@ionic/angular";
import {CookiesDialogComponent} from "@app/shared/components/cookies-dialog/cookies-dialog.component";
import {selectSettings} from "@core/store/public/public.selectors";
import {IBookingSettingsModel} from "@models/booking-settings.model";
import {LocalStorageService} from "@services/local-storage/local-storage.service";
import {ELocalStorageKeys} from "@enums/local-storage.enum";
import {ECookiesAccept} from "@enums/cookies-accept.enum";
import * as moment from 'moment';
import {BookingMessageDialogComponent} from "@app/shared/components/booking-message-dialog/booking-message-dialog.component";
import {GoogleAnalyticsService} from "@core/utilities/analytics/services/google-analytics.service";

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private _languageService: LanguageService,
    private _store: Store<IRootState>,
    private router: Router,
    private pixel: PixelService,
    private modalController: ModalController,
    private localStorage: LocalStorageService,
    private googleService: GoogleAnalyticsService,
  ) {}

  public ngOnInit(): void {
    this.setupLanguage();

    this.$settings.pipe(untilDestroyed(this)).subscribe(settings => {
      if (!settings) {
        return;
      }

      const cookiesAccept = this.localStorage.getItem(ELocalStorageKeys.cookies);

      if (cookiesAccept as string) {
        this.setupTracking(cookiesAccept, settings);
        const _ = this.showBookingMessage(settings);
      } else {
        const _ = this.showCookiesDialog(settings);
      }
    });
  }

  private async showCookiesDialog(settings: IBookingSettingsModel): Promise<void> {
    const topDialog = await this.modalController.getTop();

    if (topDialog?.id === 'cookies-dialog') {
      return;
    }

    const dialog = await this.modalController.create({
      component: CookiesDialogComponent,
      cssClass: 'modal-full-size',
      id: 'cookies-dialog'
    });

    await dialog.present();

    const {role} = await dialog.onDidDismiss();
    const expirationDate = moment(new Date()).add(30, 'days').toDate();

    if (role === ECookiesAccept.all) {
      this.localStorage.setItem(ELocalStorageKeys.cookies, ECookiesAccept.all, expirationDate);
    } else {
      this.localStorage.setItem(ELocalStorageKeys.cookies, ECookiesAccept.some, expirationDate);
    }

    this.setupTracking(role ?? null, settings);
    return this.showBookingMessage(settings);
  }

  private async showBookingMessage(settings: IBookingSettingsModel): Promise<void> {
    const messageAccepted = this.localStorage.getItem(ELocalStorageKeys.message_accepted);

    if (!settings.show_online_booking_popup || messageAccepted === 'yes') {
      return;
    }

    const topDialog = await this.modalController.getTop();

    if (topDialog?.id === 'cookies-dialog' || topDialog?.id === 'booking-message-dialog') {
      return;
    }

    const dialog = await this.modalController.create({
      component: BookingMessageDialogComponent,
      componentProps: {
        message: settings.show_online_booking_popup_text,
      },
      cssClass: 'modal-full-size',
      id: 'booking-message-dialog'
    });

    await dialog.present();

    const {role} = await dialog.onDidDismiss();

    if (role === 'accept') {
      const expirationDate = moment(new Date()).add(12, 'hours').toDate();
      this.localStorage.setItem(ELocalStorageKeys.message_accepted, 'yes', expirationDate);
    }
  }

  private setupTracking(accept: string | null, settings: IBookingSettingsModel) {
    if (!accept) {
      this.pixel.remove();
      return;
    }

    if (accept === ECookiesAccept.all && settings.facebook_pixel_id && settings.facebook_pixel_id?.length > 0) {
      this.pixel.initialize(settings.facebook_pixel_id);
    } else {
      this.pixel.remove();
    }

    if (accept === ECookiesAccept.all && settings.google_analytics_id && settings.google_analytics_id?.length > 0) {
      this.googleService.initialize({trackingCode: settings.google_analytics_id});
    }
  }

  private setupLanguage(): void {
    const browserLang: string = navigator.language;

    const existingLanguage: Language | undefined = this._languageService
      .getSupportedLanguages()
      .find((lang: Language) => lang.browserMatch.find((b) => b.toLowerCase() === browserLang.toLowerCase()));

    !!existingLanguage
      ? this._languageService.setLanguage(existingLanguage)
      : this._languageService.setLanguage(this._languageService.getDefaultLanguage());
  }

  protected readonly $settings = this._store.select(selectSettings);
}
