import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {NavigationEffects} from "@core/store/navigation/navigation.effects";
import {navigationReducers} from "@core/store/navigation/navigation.reducers";

@NgModule({
	imports: [
		CommonModule,
		StoreModule.forFeature('navigation', navigationReducers),
		EffectsModule.forFeature([NavigationEffects]),
	],
	providers: [NavigationEffects],
})
export class NavigationStoreModule {
}
