import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription, timer } from 'rxjs';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { LogContentService } from '@app/shared/components/log-content/services/log-content.service';
import { ILogContent, ILogContentAction } from "@app/shared/components/log-content/interfaces/log-content.interface";

@Component({
	selector: 'log-content',
	templateUrl: './log-content.component.html',
	styleUrls: ['./log-content.component.scss'],
	animations: [
		trigger('changeVisibility', [
			state(
				'hide',
				style({
					bottom: '-200px',
				})
			),
			state(
				'show',
				style({
					bottom: '30px',
				})
			),
			transition('hide=>show', animate(400)),
			transition('show=>hide', animate(400)),
		]),
	],
})
export class LogContentComponent implements OnInit, OnDestroy {
	public currentState: string = 'hide';
	public title: string = '';
	public description: string | undefined;
	public cssClass: string = '';
	public actions: ILogContentAction[] | undefined = [];

	public includeCloseAction: boolean = true;

	public timerSubscription: Subscription | undefined;

	public closeAction: ILogContentAction = {
		action: this.closeSelf.bind(this),
		text: 'Ok',
	};

	private subscriptions: Subscription[] = [];

	constructor(private cdr: ChangeDetectorRef, private logContentService: LogContentService) {}

	public ngOnInit(): void {
		this.subscriptions.push(
			this.logContentService.logContentChange.subscribe((logContent: ILogContent) => {
				if (this.timerSubscription) {
					this.timerSubscription.unsubscribe();
				}

				if (!logContent) {
					this.currentState = 'hide';

					return;
				}

				this.cssClass = logContent.level;
				this.description = logContent.description;
				this.actions = logContent.actions;
				this.includeCloseAction = !logContent.skipCloseAction;
				this.currentState = 'show';

				let duration = logContent.forceDuration;

				if (!duration) {
					duration = 5000;
				}

				this.timerSubscription = timer(duration).subscribe((t) => {
					this.currentState = 'hide';
					this.cdr.detectChanges();
				});

				this.cdr.detectChanges();
			})
		);
	}

	public callContentAction(action: ILogContentAction): void {
		action.action(action.value);
	}

	public closeSelf(): void {
		if (this.timerSubscription) {
			this.timerSubscription.unsubscribe();
		}

		this.currentState = 'hide';
	}

	public ngOnDestroy(): void {
		this.subscriptions.forEach((s) => s.unsubscribe());
		if (this.timerSubscription) {
			this.timerSubscription.unsubscribe();
		}
	}
}
