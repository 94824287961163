import { Injectable } from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import {
	ENavigationActions,
	NavigateTo,
} from "@core/store/navigation/navigation.actions";
import {LocalStorageService} from "@services/local-storage/local-storage.service";

@Injectable()
export class NavigationEffects {

	constructor(
		private router: Router,
		private actions$: Actions,
		private localStorageService: LocalStorageService
	) {}

	public onNavigateTo$ = createEffect(() => this.actions$.pipe(ofType<NavigateTo>(ENavigationActions.NavigateTo),
		map((navigateTo) => {
			const _ = this.router.navigate(navigateTo.commands, { queryParams: navigateTo.queryParams });
		})
	), {dispatch: false});

}
