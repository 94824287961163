import { Injectable } from '@angular/core';
import { IAccountInformationModel } from '@models/account-information.model';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { IRootState } from '@core/store/root.state';
import { filter, Observable, take } from 'rxjs';
import { FetchAccountInformation } from '@core/store/public/public.actions';
import { selectPublicAccountInformation } from '@core/store/public/public.selectors';

@Injectable({
  providedIn: 'root',
})
export class SubdomainResolver implements Resolve<IAccountInformationModel> {
  constructor(private store: Store<IRootState>) {}

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IAccountInformationModel> {
    const url = window.location.hostname;
    const subdomain = url.split('.')[0];

    if (subdomain === 'dev') {
      const url = new URL(window.location.href);
      const queryParams = new URLSearchParams(url.search);
      const accountId = queryParams.get('account');

      if (accountId) {
        this.store.dispatch(new FetchAccountInformation(accountId));
      } else {
        this.store.dispatch(new FetchAccountInformation(subdomain));
      }
    } else {
      this.store.dispatch(new FetchAccountInformation(subdomain));
    }

    return this.store.select(selectPublicAccountInformation).pipe(
      filter((account): account is IAccountInformationModel => account != undefined && !!account),
      take(1)
    );
  }
}
