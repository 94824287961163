import {Component, Input} from '@angular/core';
import {TranslateModule} from "@ngx-translate/core";
import {Store} from "@ngrx/store";
import {IRootState} from "@core/store/root.state";
import {selectPublicAccountInformation} from "@core/store/public/public.selectors";
import {AsyncPipe} from "@angular/common";
import {ModalController} from "@ionic/angular";

@Component({
  selector: 'app-booking-message-dialog',
  templateUrl: './booking-message-dialog.component.html',
  styleUrls: ['./booking-message-dialog.component.scss'],
  imports: [
    TranslateModule,
    AsyncPipe
  ],
  standalone: true
})
export class BookingMessageDialogComponent {
  @Input() public message: string = '';

  constructor(
    private store: Store<IRootState>,
    private modalController: ModalController,
  ) {}

  public didClickAccept(): void {
    this.modalController.dismiss(null, 'accept');
  }

  protected readonly $accountInfo = this.store.select(selectPublicAccountInformation);
}
