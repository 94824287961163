import { PaginationHolder } from '@models/pagination.model';
import { ICategoryModel } from '@models/category.model';
import { IBookingHourModel } from '@models/booking-hour.model';
import { ISelectedTimeslotModel } from '@app/public/pages/select-time/pipes/split-timeslots.model';
import { ISMSChallengeRequestModel } from '@models/sms-challenge-request.model';
import { IAccountInformationModel } from '@models/account-information.model';
import { ICustomerModel } from '@models/customer.model';
import { ITokenModel } from '@models/token.model';
import { ICartItemModel } from '@models/cart-item.model';
import { ICartPriceModel } from '@models/cart-price.model';
import { ICompletedBookingModel } from '@models/completed-booking.model';
import { IOpeningHoursModel } from '@models/opening-hours.model';
import { IBookingSettingsModel } from '@models/booking-settings.model';
import { WaitlistInfo } from '@app/public/pages/waitlist/waitlist.component';
import { IBookingThemeSettings } from '@models/booking-theme-settings.model';

export interface IPublicState {
  isLoading: boolean;
  subdomain?: string;
  account_id?: string;
  accountInformation?: IAccountInformationModel;
  themeSettings?: IBookingThemeSettings;
  settings?: IBookingSettingsModel;
  openingHours?: PaginationHolder<IOpeningHoursModel>;
  categories?: PaginationHolder<ICategoryModel>;
  cart: ICartItemModel[];
  isCartPriceLoading: boolean;
  cartPrice?: ICartPriceModel;
  availableBookingHours: IBookingHourModel[];
  selectedTimeSlot?: ISelectedTimeslotModel;
  smsChallenge?: ISMSChallengeRequestModel;
  customer?: ICustomerModel;
  customer_t?: ITokenModel;
  completedBooking?: ICompletedBookingModel;
  waitlistInfo: WaitlistInfo | undefined;
}

export const initialPublicState: IPublicState = {
  isLoading: false,
  cart: [],
  isCartPriceLoading: false,
  availableBookingHours: [],
  waitlistInfo: undefined,
};
