import { Injectable, Injector } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { registerLocaleData } from '@angular/common';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { from } from 'rxjs';
import {Language} from "@app/core/services/language/language-base.service";
import langDa from "@angular/common/locales/da";
import langNb from "@angular/common/locales/nb";
import langEn from "@angular/common/locales/en-GB";
registerLocaleData(langDa);
registerLocaleData(langNb);
registerLocaleData(langEn);

@Injectable({
	providedIn: 'root',
})
export class LanguageService {
	constructor(private injector: Injector, private translateService: TranslateService) {}

	private get momentDateAdapter(): MomentDateAdapter {
		return this.injector.get(MomentDateAdapter);
	}

	private supportedLanguages: Language[] = [
		new Language(
			'English',
			'en',
			null,
			'en-gb',
			'en-GB',
			[
				'en',
				'en-AU',
				'en-BZ',
				'en-CA',
				'en-CB',
				'en-GB',
				'en-IE',
				'en-JM',
				'en-NZ',
				'en-PH',
				'en-TT',
				'en-US',
				'en-ZA',
				'en-ZW',
			],
			'en',
			'en-us'
		),
		new Language('Dansk', 'da', null, 'da', 'da', ['da', 'da-dk'], 'da', 'da'),
		new Language('Norsk', 'no', null, 'nb', 'nb', ['nb', 'nb-NO', 'nn-NO'], 'no', 'no'),
	];

	private defaultLanguage: Language = this.supportedLanguages[0];

	public getDefaultLanguage(): Language {
		return this.defaultLanguage;
	}

	public getSupportedLanguages(): Language[] {
		return this.supportedLanguages;
	}

	public setLanguage(language: Language): void {
		this.translateService.use(language.languageKey);

		if (language.angularKey === 'en-US') {
			moment.locale(language.momentKey);
		} else {
			this.momentSetLocale(language);
		}
	}

	public getCurrentLanguage(): Language {
		const lan = this.supportedLanguages.find((l) => l.languageKey === this.translateService.currentLang);

		return lan ? lan : this.defaultLanguage;
	}

	private momentSetLocale(language: Language): void {
		from(
			import(
				/* webpackInclude: /(nb|da|en\-gb)\.js$/ */
				`moment/locale/${language.momentKey}.js`
				)
		).subscribe(() => {
			moment.locale(language.momentKey);
			this.momentDateAdapter.setLocale(language.momentKey);
		});
	}
}
