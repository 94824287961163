import {ErrorHandler} from "@angular/core";
import * as Sentry from "@sentry/angular-ivy";
import {environment} from "@env/environment";
import {EEnvironment} from "@enums/environment.enum";
import packageJson from '../../../../../package.json';

export class SentryErrorHandler implements ErrorHandler {
	constructor() {
		Sentry.init({
			dsn: environment.sentryDsn,
			environment: environment.env === EEnvironment.production ? 'production' : 'dev',
			release: environment.sentryVersionPrefix + '.' + packageJson.version,
      integrations: [
        new Sentry.BrowserTracing({
          routingInstrumentation: Sentry.routingInstrumentation,
        }),
        new Sentry.Replay(),
      ],

      tracesSampleRate: 1.0,

      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", /^https:\/\/api.bodil\.io\//],

      // Capture Replay for 10% of all sessions,
      // plus for 100% of sessions with an error
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
		});
	}

	public handleError(err: any): void {
		console.error(err);

		// If chunk loading error, then reload the page.
		const chunkFailedMessage = /Loading chunk [\d]+ failed/;

		if (chunkFailedMessage.test(err.message)) {
			window.location.reload();

			return;
		}

		if (!navigator.onLine) {
			// if not online
			const noInternetHandler = () => {
				if (navigator.onLine) {
					window.removeEventListener('online', noInternetHandler);
					window.location.reload(); // reload window when online

					return;
				}
			};
			window.addEventListener('online', noInternetHandler);
		} else {
			Sentry.captureException(err.originalError || err); // send error to Raven
		}
	}
}
