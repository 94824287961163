<div class="relative h-full w-full">
  <div
    class="absolute left-2 right-2 top-1/2 flex max-h-full -translate-y-1/2 flex-col gap-6 overflow-hidden rounded-lg bg-white p-4 sm:left-1/2 sm:min-w-[450px] sm:-translate-x-1/2 md:max-w-[450px]">
    <div class="flex flex-col gap-2 overflow-scroll">
      <h2>{{ "booking.popup.title" | translate }} {{ ($accountInfo | async)?.name }}</h2>
      <p class="whitespace-pre-line text-brand-gray-600">{{ message }}</p>
    </div>

    <div class="flex flex-col gap-4">
      <button
        (click)="didClickAccept()"
        class="primary-button !rounded-full !bg-custom-secondary !py-5 !font-bold !text-custom-secondaryText">
        {{ "booking.button.accept" | translate }}
      </button>
    </div>
  </div>
</div>
