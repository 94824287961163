import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { switchMap } from "rxjs/operators";
import { of } from "rxjs";
import { EErrorActions, LoadError } from "@app/core/store/error/error.actions";
import { HttpErrorResponse } from "@angular/common/http";
import { TranslateService } from "@ngx-translate/core";
import { ELogLevel } from "@app/shared/components/log-content/enums/log-level.enum";
import { LogContentService } from "@app/shared/components/log-content/services/log-content.service";

@Injectable()
export class ErrorEffects {

	public onLoad$ = createEffect(() => {
		return this.actions$.pipe(
			ofType<LoadError>(EErrorActions.LoadError),
			switchMap((error: LoadError) => {
				const e = error.error;
				switch (true) {
					case e instanceof HttpErrorResponse: {

						const status = (e as HttpErrorResponse).status;
						if (status === 401) {
							this.logService.logContent({
								level: ELogLevel.WARNING,
								description: this.translate.instant('error.unauthorizedDescription'),
								actions: [],
							});
						}  else if (status >= 400 && status < 500) {

							const errorMessage = this.getErrorMessage((e as HttpErrorResponse));

							if (errorMessage) {
								this.logService.logContent({
									level: ELogLevel.ERROR,
									description: this.translate.instant(errorMessage),
									actions: [],
								});
							} else {
								this.logService.logContent({
									level: ELogLevel.ERROR,
									description: this.translate.instant('error.clientErrorDescription'),
									actions: [],
								});
							}
						} else if (status >= 500) {
							this.logService.logContent({
								level: ELogLevel.ERROR,
								description: this.translate.instant('error.serverErrorDescription'),
								actions: [],
							});
						} else if (status === 0) {
							this.logService.logContent({
								level: ELogLevel.ERROR,
								description: this.translate.instant('error.serverErrorDescriptionCORS'),
								actions: [],
							});
						}
						break;
					}
					case error instanceof RangeError:
					case error instanceof ReferenceError:
					case error instanceof SyntaxError:
					case error instanceof TypeError:
					case error instanceof URIError:
					default: {
						console.error(error);
						this.logService.logContent({
							description: this.translate.instant('error.unknownErrorDescription'),
							level: ELogLevel.ERROR,
							actions: [],
						});
					}
				}

				return of({type: 'ERROR: Something bad happened'});
			}),
		)
	});

	constructor(
		private logService: LogContentService,
		private translate: TranslateService,
		private actions$: Actions,
	) {
	}

	private getErrorMessage(error: HttpErrorResponse): string | null {
		if (error && error.error) {
      const errorMessage = error.error.error_message as string;

			if (errorMessage && errorMessage.length > 0) {
				return errorMessage;
			} else if (error.error.error && error.error.error !== '') {
				return error.error.error;
			} else if (error.error.email && error.error.email[0] && error.error.email[0] !== '') {
				return error.error.email[0];
			}
		}

		if (error && error.message) {
			return error.message;
		}

    return null;
	}

	private handleErrors(errorCodes: any, serverResponse: any): void {
		let errors = '';

		if (serverResponse['error_message'] && serverResponse['error_message'].length > 0) {
			errors = serverResponse['error_message'];
		} else {
			for (const field in errorCodes as { [key: string]: [] }) {
				errors += errorCodes[field] + '\n';
			}
		}

		this.logService.logContent({
			level: ELogLevel.ERROR,
			description: errors,
			actions: [],
		});
	}
}
